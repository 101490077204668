<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <h6 class="mb-2 text-black">Mill</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="millModel" multiple column @change="(event) => millOnChange(event)"> 
                                        <v-chip label v-for="item in millLists" :key="item.mill_id" :value="item.mill_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white"  small dense>
                                            {{item.mill_name}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Regular Moving</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="regularModel" column @change="(event) => regularOnChange(event)"> 
                                        <v-chip label v-for="item in regularLists" :key="item.value" :value="item.value" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.text}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Category</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="categoryModel" column @change="(event) => categoryOnChange(event)"> 
                                        <v-chip label v-for="item in categoryLists" :key="item.category" :value="item.category" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.category}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Material</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="materialModel" multiple column @change="(event) => materialOnChange(event)"> 
                                        <v-chip label v-for="item in materialLists" :key="item.jenis" :value="item.jenis" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.jenis}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Grade</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="gradeModel" multiple column @change="(event) => gradeOnChange(event)"> 
                                        <v-chip label v-for="item in gradeLists" :key="item.grade_id" :value="item.grade_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.grade_id}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Color</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="colorModel" multiple column @change="(event) => colorOnChange(event)"> 
                                        <v-chip label v-for="item in colorLists" :key="item.color_id" :value="item.color_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.NamaWarna}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Thickness</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="thicknessModel" multiple column @change="(event) => thicknessOnChange(event)"> 
                                        <v-chip label v-for="item in thicknessLists" :key="item.thick" :value="item.thick" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{ item.thick }} mm
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Width</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="widthModel" multiple column @change="(event) => widthOnChange(event)"> 
                                        <v-chip label v-for="item in widthLists" :key="item.width" :value="item.width" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{ $store.getters.convertToCurrency(item.width) }} mm
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Quality</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="qualityModel" multiple column @change="(event) => qualityOnChange(event)"> 
                                        <v-chip label v-for="item in qualityLists" :key="item.quality_id" :value="item.quality_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.quality_id}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Availability</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="availabilityModel" column> 
                                        <v-chip label v-for="item in availabilityLists" :key="item.value" :value="item.value" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.text}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" :min="minDate" :max="maxDate" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" :min="minDate" :max="maxDate" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-xs-6">
                                <v-radio-group v-model="rdOrderOnlyModel" row>
                                    <v-radio dense label="All Material" value="N"></v-radio>
                                    <v-radio dense label="Only order" value="Y"></v-radio>
                                    <v-radio dense label="Shortage" value="S"></v-radio>
                                </v-radio-group>
                            </div>
                            <div class="col-lg-3 text-right">
                                <v-btn class="mr-4 mt-3" color="primary" elevation="2" small @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            
                        </div>
                            
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardGraph">

                <div class="card elevation-5">
                    <div class="card-body">
                        
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                            
                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogDetails" transition="dialog-bottom-transition" max-width="80%">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <!-- <div class="d-flex w-25 text-right">
                                        <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                        </div>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                        <div class="card-footer text-muted text-right">
                            <v-btn color="red" @click="dialogDetails = !dialogDetails" dark>Close</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Raw Mats. Balance',
                disabled: true,
                href: '#',
                },
            ],
            millModel: [],
            millLists: [],
            materialModel: [],
            materialLists: [],
            gradeModel: [],
            gradeLists: [],
            colorModel: [],
            colorLists: [],
            thicknessModel: [],
            thicknessLists: [],
            widthModel: [],
            widthLists: [],
            qualityModel: [],
            qualityLists: [],
            categoryModel: '',
            categoryLists: [],
            availabilityModel: '',
            availabilityLists: [
                {
                    value: 'Y',
                    text: 'AVAILABLE',
                },
                {
                    value: 'N',
                    text: 'NOT AVAILABLE',
                },
            ],
            regularModel : '',
            regularLists: [],
            rdOrderOnlyModel: 'N',
            dateStartModal: false,
            dateStartModel: '',
            dateEndModal : false,
            dateEndModel: '',
            dateStartFormatted: '',
            dateEndFormatted: '',
            dialogDetails: false,
            itemDetailLists: [],
            headersDetails: [
                { text: 'MILL.', value: 'MILL_NAME', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MATERIAL.', value: 'MATERIAL', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'COLOR', value: 'COLOR', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'THICKNESS.', value: 'THICKNESS', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WIDTH.', value: 'WIDTH', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GRADE.', value: 'GRADE', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QUALITY.', value: 'QUALITY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT OH (Ton)', value: 'WGT_ON_HAND', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT ON HAND', value: 'WGT_ON_HAND', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT CONTRACT', value: 'WGT_CONTRACT', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT RESERVE', value: 'WGT_RESERVE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT UNRELEASE', value: 'WGT_UNRELEASE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT AVAILABLE', value: 'WGT_AVAILABLE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'WGT PO', value: 'WGT_PO', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'MIN WGT', value: 'MIN_WGT', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH ON HAND', value: 'LENGTH_ON_HAND', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH CONTRACT', value: 'LENGTH_CONTRACT', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH RESERVE', value: 'LENGTH_RESERVE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH UNRELEASE', value: 'LENGTH_UNRELEASE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH AVAILABLE', value: 'LENGTH_AVAILABLE', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'LENGTH PO', value: 'LENGTH_PO', align: 'right', class: 'primary--text blue lighten-5' },
                // { text: 'MIN LENGTH', value: 'MIN_LENGTH', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'DATE', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            minDate: '',
            maxDate: '',
            exportToExcelLists: []
        }
    },
    async mounted(){
        
        this.initialize()
        

    },
    methods:{
        
        async initialize(){

            var today = new Date();
            var daysAgo = new Date(new Date().setDate(today.getDate() - 30))

            this.minDate = daysAgo.toISOString().slice(0,10)
            this.maxDate = today.toISOString().slice(0,10)

            $(".cardGraph").hide()
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.categoryLists= [
                    { category: 'ROOF', },
                    { category: 'TRUSS' },
                ]

                this.regularLists= [
                    { text: 'REGULAR', value: 'Y' },
                    { text: 'NON REGULAR', value: 'N' },
                ]

                this.millLists = res.data.mill
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality
                this.$store.dispatch('setOverlay', false)
            })
            
            // this.getChartData()

        },

        async getChartData(){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                return false
            }

            if(this.dateStartModel == '' || this.dateEndModel == ''){
                Swal.fire({
                    text: 'Please select date first !',
                    icon: 'error',
                })

                return false
            }
    
            this.itemLists = []
            this.exportToExcelLists = []
            this.$store.dispatch('setOverlay', true)
            $(".cardGraph").hide()

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    material: this.materialModel ? this.materialModel : [],
                    grade: this.gradeModel ? this.gradeModel : [],
                    color: this.colorModel ? this.colorModel : [],
                    thickness: this.thicknessModel ? this.thicknessModel : [],
                    width: this.widthModel ? this.widthModel : [],
                    quality: this.qualityModel ? this.qualityModel : [],
                    product: this.categoryModel ? this.categoryModel : '',
                    regular: this.regularModel ? this.regularModel : '',
                    availability: this.availabilityModel ? this.availabilityModel : '',
                    orderOnly : this.rdOrderOnlyModel ? this.rdOrderOnlyModel : '',
                    dateFrom: this.dateStartFormatted ? this.dateStartFormatted : '',
                    dateTo: this.dateEndFormatted ? this.dateEndFormatted : ''
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.data.length != 0){
                    $(".cardGraph").show()
                    this.renderChart(res.data.data)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    $(".cardGraph").hide()
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            

        },

        renderChart(data){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "Raw Mats. Balance Analysis"
                },
                subtitles: [{
                    text: "Click for details",		
                    fontColor: "grey",
                }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                    },
                },
                axisY: {
                    title: "Weight",
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails,
                    name: 'Balance',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "top",  
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,### Ton",
                    indexLabelFontColor: "#4245f5",
                    // color: "#0f4c5c",
                    dataPoints: data,
                }]
            })

            chart.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        async getDetails(e){

            this.itemDetailLists = []
            this.dialogDetails = true
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/getDetails`, { 

                mill: this.millModel ? this.millModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: this.colorModel ? this.colorModel : [],
                thickness: this.thicknessModel ? this.thicknessModel : [],
                width: this.widthModel ? this.widthModel : [],
                quality: this.qualityModel ? this.qualityModel : [],
                product: this.categoryModel ? this.categoryModel : '',
                regular: this.regularModel ? this.regularModel : '',
                availability: this.availabilityModel ? this.availabilityModel : '',
                orderOnly : this.rdOrderOnlyModel ? this.rdOrderOnlyModel : '',
                date: e.dataPoint.label

            },{
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {

                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
                this.exportToExcelLists = res.data.result

            })

        },

        async millOnChange(id){

            this.regularModel = ''
            this.categoryModel = ''
            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel,

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async regularOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.regularModel = []

                return false
            }

            this.$store.dispatch('setOverlay', true)

            this.categoryModel = ''
            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : '',
                regular: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })          

        },

        async categoryOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.categoryModel = []

                return false
            }

            this.$store.dispatch('setOverlay', true)

            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : '',
                regular: this.regularModel ? this.regularModel : '',
                product: id,


            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async materialOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.materialModel = []

                return false
            }

            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async gradeOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.gradeModel = []

                return false
            }

            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: id             

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async colorOnChange(id){

            console.log(id);

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.colorModel = []

                return false
            }

            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {

                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async thicknessOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.thicknessModel = []

                return false
            }

            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: this.colorModel ? this.colorModel : [],
                thickness: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async widthOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.widthModel = []

                return false
            }

            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/RawMatBalance/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: this.colorModel ? this.colorModel : [],
                thickness: this.thicknessModel ? this.thicknessModel : [],
                width: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async qualityOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.qualityModel = []

                return false
            }

        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "RawMat-Balance-Analysis-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

    div.scrollDown {
        margin: 4px, 4px;
        padding: 4px;
        width: 100%;
        height: 120px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: justify;
        border-style: solid;
        border-radius: 5px;
        border-color: rgb(95, 185, 207);
        border-width: 2px;
    }

</style>